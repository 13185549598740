<template>
  <div class="hotel-order-detail">
    <w-dialog
      ref="detailDialogRef"
      title="酒店订单详情"
      width="60%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
      class="order-detail"
    >
      <div class="print-btn" @click="handlePrint">
        <img src="@/assets/img/printer.png" />
        <div>打印</div>
      </div>

      <el-descriptions :column="3" class="basic-info" border>
        <el-descriptions-item
          v-for="(el, index) in basicInfo"
          :key="index"
          :label="el.label"
          ><span
            :style="{
              color: el.color ? el.color[el.value] : '',
            }"
          >
            {{ el.text ? el.text[el.value] : el.value }}</span
          ></el-descriptions-item
        >
      </el-descriptions>
      <div class="font-999">游客信息</div>
      <common-table
        ref="peopleTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="tableData"
        :columns="peopleTableColumns"
        @openCheckInDialog="openCheckInDialog"
      >
        <template #checkIn="{ row }">
          <template
            v-if="
              row.status == 1 &&
              authData.indexOf('QigKyLNVqXGgcsdMrEdfPQqTgRa1vx') != -1 &&
              detailInfo?.pay_status == 2 &&
              [3, 6].includes(detailInfo?.work_status)
            "
          >
            <el-button @click="openCheckInDialog(row)">入住</el-button>
          </template>
        </template>
      </common-table>
      <br />
      <div class="font-999">退款明细</div>
      <common-table
        ref="refundTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="refundData"
        :columns="refundTableColumns"
      >
      </common-table>
    </w-dialog>
    <confirm-dialog
      ref="checkInDialog"
      @wConfirm="handleCheckIn"
      title="确认为该游客办理入住吗？"
    ></confirm-dialog>
    <PrintHotelDialog ref="printHotelRef"></PrintHotelDialog>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import { OrderApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog.vue";
import PrintHotelDialog from "./PrintHotelDialog.vue";

export default {
  emits: ["submit"],
  components: { ConfirmDialog, PrintHotelDialog },
  setup() {
    const detailDialogRef = ref(null);
    const basicInfo = ref({});
    const detailInfo = ref({});
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    const refundData = ref([]);
    const authInfo = ref({});
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const printHotelRef = ref(null);
    function handlePrint() {
      closeDialog();
      printHotelRef.value.openDialog(
        basicInfo.value,
        tableData.value,
        refundData.value
      );
    }
    const tableData = ref([]);
    const peopleTableColumns = ref([
      {
        prop: "name",
        label: "游客名",
      },
      {
        prop: "mobile",
        label: "手机号",
      },
      {
        prop: "idcard",
        label: "身份证",
      },
      {
        prop: "status",
        label: "入住状态",
        type: "option",
        metaData: ["", "未入住", "已入住"],
        colorData: ["", "#F56C6C", "#67C23A"],
      },
      {
        type: "operation",
        prop: "",
        label: "操作",
        minWidth: 120,
        bottons: [
          {
            type: "customRender",
            action: "checkIn",
          },
        ],
      },
    ]);

    function getDetail(type) {
      OrderApi.hotelOrderDetail({ sn: snId.value }).then((res) => {
        if (res.Code === 200) {
          tableData.value = res.Data.reservation;
          refundData.value = res.Data.refund || [];
          detailInfo.value = res.Data;
          basicInfo.value = [
            {
              label: "套餐名称",
              value: detailInfo.value.hotel_package
                ? detailInfo.value.hotel_package +
                  "×" +
                  detailInfo.value.buy_num +
                  "间"
                : "-",
            },

            {
              label: "所属房型",
              value: detailInfo.value.hotel_package_type
                ? detailInfo.value.hotel_package_type
                : "-",
            },

            {
              label: "所属酒店",
              value: detailInfo.value.hotel_name
                ? detailInfo.value.hotel_name
                : "-",
            },
            {
              label: "订单号码",
              value: detailInfo.value.sn ? detailInfo.value.sn : "-",
            },
            {
              label: "购买渠道",
              value: detailInfo.value.source_text
                ? detailInfo.value.source_text
                : "-",
            },
            {
              label: "支付方式",
              value: detailInfo.value.payway_name
                ? detailInfo.value.payway_name
                : "-",
            },
            {
              label: "下单时间",
              value: detailInfo.value.ctime ? detailInfo.value.ctime : "-",
            },
            {
              label: "支付金额",
              value: detailInfo.value.pay_money
                ? detailInfo.value.pay_money + "元"
                : "-",
            },
            {
              label: "退款金额",
              value: detailInfo.value.refund_money
                ? detailInfo.value.refund_money + "元"
                : "-",
            },

            {
              label: "支付时间",
              value: detailInfo.value.pay_time
                ? detailInfo.value.pay_time
                : "-",
            },

            {
              label: "支付状态",
              value: detailInfo.value.pay_status
                ? detailInfo.value.pay_status
                : "-",
              color: allStatusData.value?.payStatus?.colors,
              text: allStatusData.value?.payStatus?.options,
            },
            {
              label: "订单状态",
              value: detailInfo.value.work_status
                ? detailInfo.value.work_status
                : "-",
              color: allStatusData.value?.workStatus?.colors,
              text: allStatusData.value?.workStatus?.options,
            },
            {
              label: "入住日期",
              value: detailInfo.value.sdate
                ? detailInfo.value.sdate + "至" + detailInfo.value.edate
                : "-",
            },

            {
              label: "确认时间",
              value: detailInfo.value.confirm_time
                ? detailInfo.value.confirm_user
                  ? detailInfo.value.confirm_time +
                    "(" +
                    detailInfo.value.confirm_user +
                    ")"
                  : detailInfo.value.confirm_time
                : "-",
            },
          ];
          type == 1 ? detailDialogRef.value.show() : "";
          nextTick(() => {
            if (tableData.value && tableData.value.length != 0) {
              peopleTableRef.value.tableLoad();
            }
            if (refundData.value.length != 0) {
              refundTableRef.value.tableLoad();
            }
          });
        } else {
          let msg = res.Message ? res.Message : "获取详情数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    const snId = ref(null);
    const peopleTableRef = ref(null);
    const refundTableRef = ref(null);
    const refundTableColumns = ref([
      {
        prop: "refund_sn",
        label: "退款单号",
        minWidth: 140,
      },
      {
        prop: "refund_money",
        label: "退款金额",
      },
      {
        prop: "refund_payway_name",
        label: "退款方式",
      },
      {
        prop: "refund_confirm_user",
        label: "退款确认人",
      },
      {
        prop: "refund_confirm_time",
        label: "退款时间",
      },
      {
        prop: "refund_remark",
        label: "退款原因",
      },
    ]);

    const allStatusData = ref({});
    function openDialog(id, data) {
      allStatusData.value = data;
      snId.value = id;
      getDetail(1);
    }
    function closeDialog() {
      detailDialogRef.value.close();
    }
    const checkInDialog = ref(null);
    const currentCheckIn = ref({});
    function openCheckInDialog(row) {
      currentCheckIn.value = {
        ohr_id: row.id,
        sn: detailInfo.value.sn,
      };
      checkInDialog.value.show();
    }
    function handleCheckIn() {
      checkInDialog.value.isLoading = true;
      OrderApi.checkInHotelOrder(currentCheckIn.value).then((res) => {
        checkInDialog.value.isLoading = false;
        if (res.Code === 200) {
          checkInDialog.value.close();
          getDetail(2);
          ElMessage.success("操作成功");
        } else {
          let msg = res.Message ? res.Message : "操作失败！";
          ElMessage.error(msg);
        }
      });
    }
    return {
      closeDialog,
      basicInfo,
      detailDialogRef,
      openDialog,
      detailInfo,
      authInfo,
      authData,
      peopleTableRef,
      tableData,
      peopleTableColumns,
      openCheckInDialog,
      handleCheckIn,
      checkInDialog,
      refundData,
      refundTableRef,
      refundTableColumns,
      handlePrint,
      printHotelRef,
    };
  },
};
</script>

<style lang="scss">
.hotel-order-detail {
  .print-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 20px;
      height: 20px;
    }
    div {
      width: 40px;
      padding: 10px 0;
      text-align: center;
      color: var(--theme-color);
    }
  }
  .order-detail {
    .el-dialog__body {
      padding-bottom: 200px;
    }

    .basic-info {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 16px 30px 12px 30px;
      background-color: var(--search-uncheck-bg-color);
      .el-descriptions__body {
        border: none;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__label,
        .el-descriptions__content {
          border: 1px solid transparent;
          background-color: var(--search-uncheck-bg-color);
        }
        .el-descriptions__label {
          width: 64px;
          color: var(--text-gray-color);
          font-weight: normal;
        }
        .el-descriptions__cell {
          padding: 0 0 4px 0;
        }
        .el-descriptions__content {
          color: var(--text-color);
          min-width: 100px;
        }
      }
    }
    .refund-desc {
      > span {
        color: var(--text-third-color);
      }
      .info {
        background-color: var(--search-uncheck-bg-color);
        padding: 16px 30px;
        border-radius: 4px;
        color: var(--text-gray-color);
      }
    }
  }
}
</style>
