<template>
  <div class="print-hotel-dialog">
    <w-dialog
      ref="dialogRef"
      title=" "
      width="70%"
      top="10vh"
      :hideFooter="true"
    >
      <div class="print-btn" @click="handlePrint" ref="printText">
        <img src="@/assets/img/printer.png" />
        <div>打印</div>
      </div>
      <h1>酒店订单详情</h1>
      <br />
      <div class="print-title">订单详情</div>
      <div class="print-flex">
        <template v-for="(el, index) in orderList" :key="index">
          <div class="print-item" style="width: 33.3%">
            <div class="label">{{ el.label }}</div>
            <div class="value">
              {{ el.text ? el.text[el.value] : el.value }}
            </div>
          </div>
        </template>
      </div>
      <br />
      <br />
      <div class="print-title">游客信息</div>
      <div class="print-flex">
        <template v-for="(el, index) in touristList" :key="index">
          <div class="print-item" style="width: 25%">
            <div class="label">游客姓名</div>
            <div class="value">
              {{ el.name }}
            </div>
          </div>
          <div class="print-item" style="width: 25%">
            <div class="label">手机号码</div>
            <div class="value">
              {{ el.mobile }}
            </div>
          </div>
          <div class="print-item" style="width: 30%">
            <div class="label">身份证</div>
            <div class="value">
              {{ el.idcard }}
            </div>
          </div>
          <div class="print-item" style="width: 20%">
            <div class="label">入住状态</div>
            <div class="value">
              {{ ["", "未入住", "已入住"][el.status] }}
            </div>
          </div>
        </template>
      </div>
      <div class="print-title" v-if="refundList.length">退款信息</div>
      <div class="print-flex" v-if="refundList.length">
        <template v-for="(el, index) in refundList" :key="index">
          <div class="print-item" style="width: 31%">
            <div class="label">退款单号</div>
            <div class="value">
              {{ el.refund_sn }}
            </div>
          </div>
          <div class="print-item" style="width: 10%">
            <div class="label">退款金额</div>
            <div class="value">
              {{ el.refund_money }}
            </div>
          </div>
          <div class="print-item" style="width: 8%">
            <div class="label">退款方式</div>
            <div class="value">
              {{ el.refund_payway_name }}
            </div>
          </div>
          <div class="print-item" style="width: 10%">
            <div class="label">退款确认人</div>
            <div class="value">
              {{ el.refund_confirm_user }}
            </div>
          </div>
          <div class="print-item" style="width: 16%">
            <div class="label">退款时间</div>
            <div class="value">
              {{ el.refund_confirm_time }}
            </div>
          </div>
          <div class="print-item" style="width: 25%">
            <div class="label">退款原因</div>
            <div class="value">
              {{ el.refund_remark }}
            </div>
          </div>
        </template>
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const dialogRef = ref(null);
    const orderList = ref([]);
    const touristList = ref([]);
    const refundList = ref([]);
    function openDialog(basicInfo, tableData, refundData) {
      orderList.value = basicInfo;
      touristList.value = tableData;
      refundList.value = refundData;
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    const printText = ref(null);
    function handlePrint() {
      printText.value.style.display = "none";
      window.print();
      closeDialog();
    }
    return {
      openDialog,
      dialogRef,
      closeDialog,
      orderList,
      touristList,
      refundList,
      handlePrint,
      printText,
    };
  },
};
</script>

<style lang="scss">
.print-hotel-dialog {
  .el-dialog__header {
    padding: 0;
    background-color: var(--bg-content-color);
  }
  .el-dialog__close {
    display: none;
  }
  h1 {
    text-align: center;
    color: var(--text-color);
    font-weight: bold;
  }
  .print-title {
    width: 100%;
    padding: 4px 12px;
    color: var(--theme-color);
    font-weight: bold;
    background-color: #d4e6fc;
    border-radius: 4px;
    font-size: 16px;
  }
  .print-flex {
    display: flex;
    flex-wrap: wrap;
    .print-item {
      .label {
        color: var(--text-third-color);
        margin: 10px 0;
      }
      .value {
        color: var(--text-color);
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
